require(defaultTpl + 'js/basket.js');

import 'lazysizes';

require(tplCfgDir + 'js/mein.js');


require(tplCfgDir + 'css/style.css');
require(tplCfgDir + 'css/bootstrap.min.css');
require(tplCfgDir + 'css/bootstrap-social.css');
require(tplCfgDir + 'css/font-awesome.css');
require(tplCfgDir + 'fancybox/jquery.fancybox.css');

require(defaultTpl + 'css/basket.css');


